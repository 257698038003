import { Modal, Button } from 'react-bootstrap';
import './main.css';

const ConsentModal = (props) => {
    const { closeResponse, isTranslated } = props;

    return (
        <Modal 
            show={true} 
            onHide={closeResponse} 
            size="lg"
            className='consent-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        isTranslated ? 'Kebijakan Perlindungan dan Berbagi Data' : 'Data Sharing and Protection Policy'
                    }
                            
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe src="https://docs.google.com/document/d/e/2PACX-1vSlJIP-gQIBmnKd0WXjdasuBpYfIsVArhQODZyzsHzwZDTsXv7Ip_ll1jUoXvcUQA/pub?embedded=true" height="100%" width="100%"></iframe>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={closeResponse}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default ConsentModal;

